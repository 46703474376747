exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-emojipasta-copy-js": () => import("./../../../src/pages/emojipasta copy.js" /* webpackChunkName: "component---src-pages-emojipasta-copy-js" */),
  "component---src-pages-emojipasta-js": () => import("./../../../src/pages/emojipasta.js" /* webpackChunkName: "component---src-pages-emojipasta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-prototype-js": () => import("./../../../src/pages/prototype.js" /* webpackChunkName: "component---src-pages-prototype-js" */),
  "component---src-pages-storyline-embed-js": () => import("./../../../src/pages/StorylineEmbed.js" /* webpackChunkName: "component---src-pages-storyline-embed-js" */)
}

